import { useState } from 'react';

import { Close } from '@mui/icons-material';

import { MainBtn, Overlay } from '@shared/styled';

import { useEventForm } from '~/hooks/use-event-form';
import { useLockScrolling } from '~/hooks/use-lock-scrolling';

import { event as GAEvent } from '~/utils/gtags.client';

import styles from './index.module.css';

import { PopupOrganizationForm } from '../organization-form/popup';
import { EventForm } from './form';

export const PopupEventForm = () => {
  const { eventFormData, setEventFormData } = useEventForm();
  const { event, handleSave } = eventFormData || {};
  const isEditing = !!event;
  useLockScrolling();
  const [showOrgForm, setShowOrgForm] = useState(false);
  const [success, setSuccess] = useState(false);

  const close = () => setEventFormData(null);

  return (
    <>
      {showOrgForm && <PopupOrganizationForm close={() => setShowOrgForm(false)} />}
      <div className={styles.Wrapper}>
        <div className={styles.Header}>
          <h1>{success ? 'Event Submitted!' : isEditing ? 'Suggest Changes' : 'Add an Event'}</h1>
          <button onClick={close}>
            <Close />
          </button>
        </div>
        {success ? (
          <div className={styles.Success}>
            <p>Thank you for creating your event.</p>
            <p>
              <strong>Your submission is being reviewed and will be live within 24hrs.</strong>
            </p>
            <p>This ensures that each event has all the required information and helps avoid duplicates.</p>
            <MainBtn
              onClick={() => {
                GAEvent('event_form_create_another_button_click');
                setSuccess(false);
              }}
            >
              Create another event
            </MainBtn>
          </div>
        ) : (
          <EventForm
            event={event}
            handleSave={handleSave}
            setShowOrgForm={() => setShowOrgForm(true)}
            close={close}
            successCallback={() => setSuccess(true)}
          />
        )}
      </div>
      <Overlay style={{ zIndex: 1004 }} onClick={close} />
    </>
  );
};
