import { useState } from 'react';

import { Close } from '@mui/icons-material';

import { MainBtn, Overlay } from '@shared/styled';

import { useLockScrolling } from '~/hooks/use-lock-scrolling';

import { event as GAEvent } from '~/utils/gtags.client';

import styles from './popup.module.css';

import { OrganizationForm } from './form';

export const PopupOrganizationForm = ({ close }: { close: () => void }) => {
  useLockScrolling();
  const [success, setSuccess] = useState(false);

  const isEditing = false;

  return (
    <>
      <div className={styles.Wrapper}>
        <div className={styles.Header}>
          <h1>{success ? 'Organization submitted!' : isEditing ? 'Suggest changes' : 'Add an organization'}</h1>
          <button onClick={close}>
            <Close />
          </button>
        </div>
        {success ? (
          <div className={styles.Success}>
            <p>Thank you for creating an organization.</p>
            <p>
              <strong>Your submission is being reviewed and will be live within 24hrs.</strong>
            </p>
            <p>This ensures that each organization is unique and has all the required information.</p>
            <p>
              In the meantime you can still create your event without an organization and update it once it has been
              approved.
            </p>
            <MainBtn
              onClick={() => {
                GAEvent('org_form_create_another_button_click');
                setSuccess(false);
              }}
            >
              Create another organization
            </MainBtn>
          </div>
        ) : (
          <OrganizationForm
            successCallback={() => {
              setSuccess(true);
            }}
            close={close}
          />
        )}
      </div>
      <Overlay style={{ zIndex: 1006 }} onClick={close} />
    </>
  );
};
