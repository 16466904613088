import { useState } from 'react';

import { Link, useLocation, useNavigate } from '@remix-run/react';

import {
  AdminPanelSettings,
  ExitToApp,
  Gavel,
  HelpCenter,
  Hub,
  Login,
  Person,
  Policy,
  YouTube,
} from '@mui/icons-material';

import { Logo } from '@shared/logo';
import { IconButton, IconLink, Overlay } from '@shared/styled';
import { Tooltip } from '@shared/tooltip';

import { useHasAccess } from '~/hooks/use-has-access';

import { useOptionalUser } from '~/utils/cookies';
import { event as GAEvent } from '~/utils/gtags.client';

import styles from './index.module.css';

import { AccountMenu, LeftWrapper, LogoutButton, StyledAvatar, Header as StyledHeader, StyledLink } from './styled';
import classNames from 'classnames';

export const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const accessAdmin = useHasAccess(['super_admin']);
  const userInfo = useOptionalUser();
  const [showAccountMenu, setShowAccountMenu] = useState(false);

  const logoutHandler = () => {
    setShowAccountMenu(false);
    setTimeout(() => {
      navigate(`/logout?redirectTo=${location.pathname}`);
    }, 100);
  };

  const loginHandler = () => {
    setShowAccountMenu(false);
  };

  const isSchedule = location.pathname.includes('/schedule') && location.pathname.includes('/events/');
  // const isHomePage = location.pathname === '/';

  return (
    <>
      <StyledHeader className={isSchedule ? styles.notSticky : ''}>
        <LeftWrapper>
          <Link to="/">
            <Logo size={150} fill="#fff" />
          </Link>
        </LeftWrapper>
        <div className={classNames(styles.PortalWrapper, 'not-printable')} id="header-portal"></div>

        <Tooltip title="Need Help?" placement="bottom" offset={{ top: 5 }}>
          <IconLink className={classNames(styles.StyledIconLink, 'not-printable')} href="mailto:help@dodgeballhub.com">
            <HelpCenter />
          </IconLink>
        </Tooltip>
        <a
          className={classNames(styles.BuyCoffeeLink, 'not-printable')}
          href="https://www.buymeacoffee.com/dodgeballhub"
          target="_blank"
          rel="noreferrer"
          onClick={() => GAEvent('buy_me_a_coffee_button')}
        >
          <img
            src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
            alt="Buy Me A Coffee"
            style={{ height: '32px', width: '114px' }}
          />
        </a>

        <div className="not-printable">
          <Tooltip disabled={showAccountMenu} placement="bottom-left" offset={{ top: 5 }} title="Account settings">
            <IconButton
              onClick={() => setShowAccountMenu(!showAccountMenu)}
              aria-controls="account-menu"
              aria-haspopup="true"
              aria-expanded={true}
            >
              <StyledAvatar>
                <Person />
              </StyledAvatar>
            </IconButton>
          </Tooltip>

          <AccountMenu shown={showAccountMenu} id="account-menu">
            {userInfo && (
              <>
                <li>
                  <StyledLink onClick={() => setShowAccountMenu(false)} to="/account">
                    <Hub />
                    My Hub
                  </StyledLink>
                </li>
                {accessAdmin && (
                  <li>
                    <StyledLink onClick={() => setShowAccountMenu(false)} to={`/admin`}>
                      <AdminPanelSettings />
                      Admin
                    </StyledLink>
                  </li>
                )}
              </>
            )}
            <li>
              <StyledLink to={`/videos`}>
                <YouTube />
                Find game videos
              </StyledLink>
            </li>
            <li>
              <StyledLink to={`/terms-and-conditions`}>
                <Gavel />
                Terms and conditions
              </StyledLink>
            </li>
            <li>
              <StyledLink to={`/privacy-policy`}>
                <Policy />
                Privacy policy
              </StyledLink>
            </li>
            <li className={styles.BuyCoffeeItem}>
              <a
                className={styles.BuyCoffeeLink}
                href="https://www.buymeacoffee.com/dodgeballhub"
                target="_blank"
                rel="noreferrer"
                onClick={() => GAEvent('buy_me_a_coffee_button')}
              >
                <img
                  src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
                  alt="Buy Me A Coffee"
                  style={{ height: '32px', width: '114px' }}
                />
              </a>
            </li>
            {!userInfo ? (
              <li>
                <StyledLink to={`/login?redirectTo=${location.pathname}`} onClick={loginHandler}>
                  <Login />
                  Login
                </StyledLink>
              </li>
            ) : (
              <li>
                <LogoutButton onClick={logoutHandler}>
                  <ExitToApp />
                  Logout
                </LogoutButton>
              </li>
            )}
          </AccountMenu>
          {showAccountMenu && (
            <Overlay style={{ background: 'none', top: 0 }} onClick={() => setShowAccountMenu(false)} />
          )}
        </div>
      </StyledHeader>
    </>
  );
};
