import { useEffect, useState } from 'react';

import { useConditionalLockScrolling } from '~/hooks/use-lock-scrolling';
import { usePrompt } from '~/hooks/use-prompt';

import styles from './styles.module.css';

import { InputBlock } from './input-block';
import { DialogActions, DialogContent, DialogHeader, MainBtn, Overlay, SecondaryBtn } from './styled';
import { withStyledComponents } from './with-styled-component';
import { useOverlay } from '~/hooks/use-overlay';

const Wrapper = withStyledComponents(styles.PromptWrapper, 'div');

export default function Prompt() {
  const { setShownPrompt } = useOverlay();
  const { lock, unlock } = useConditionalLockScrolling();
  const [mainBtn, setMainBtn] = useState<HTMLButtonElement | null>(null);
  const { approve, deny, open, options } = usePrompt();
  const [fieldValue, setFieldValue] = useState(options.defaultFieldText || '');

  useEffect(() => {
    if (mainBtn) {
      setTimeout(() => {
        mainBtn.focus();
      });
    }
  }, [mainBtn]);

  useEffect(() => {
    if (open) {
      lock();
      setShownPrompt(true);
    } else {
      unlock();
      setShownPrompt(false);
    }

    return () => {
      unlock();
      setShownPrompt(false);
    };
  }, [open, lock, unlock]);

  useEffect(() => {
    if (open) {
      setFieldValue(options.defaultFieldText || '');
    }
  }, [open, options.defaultFieldText]);

  if (!open) {
    return null;
  }

  return (
    <>
      <Wrapper>
        <DialogHeader>
          <h1>{options.title}</h1>
        </DialogHeader>
        {options.message && <DialogContent>{options.message}</DialogContent>}
        {options.field && (
          <InputBlock
            className={options.message ? styles.promptInput : undefined}
            value={fieldValue}
            updateValue={setFieldValue}
          />
        )}
        <DialogActions>
          {options.secondaryBtnText && (
            <SecondaryBtn tabIndex={1} medium onClick={deny}>
              {options.secondaryBtnText}
            </SecondaryBtn>
          )}
          <MainBtn tabIndex={0} ref={setMainBtn} medium onClick={() => approve(fieldValue)}>
            {options.primaryBtnText}
          </MainBtn>
        </DialogActions>
      </Wrapper>
      <Overlay />
    </>
  );
}
