// import styles from './index.module.css';
import { useEffect } from 'react';

import { useSearchParams } from '@remix-run/react';

import { useToast } from '~/hooks/use-toast';

export const BackendEventToast = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { show } = useToast();

  useEffect(() => {
    if (searchParams.get('login-success')) {
      show({ message: 'Successfully logged in', type: 'success' });
      searchParams.delete('login-success');
      setSearchParams(searchParams);
    }
    if (searchParams.get('logout-success')) {
      show({ message: 'Successfully logged out', type: 'success' });
      searchParams.delete('logout-success');
      setSearchParams(searchParams);
    }
    if (searchParams.get('password-reset-success')) {
      show({ message: 'Password changed successfully', type: 'success' });
      searchParams.delete('password-reset-success');
      setSearchParams(searchParams);
    }
    if (searchParams.get('confirmation-success')) {
      show({ message: 'Email confirmed. Successfully logged in', type: 'success' });
      searchParams.delete('confirmation-success');
      setSearchParams(searchParams);
    }
  }, [setSearchParams, searchParams, show]);

  return null;
};
