// import styles from './index.module.css';
import { type PropsWithChildren } from 'react';

import { useLocation } from '@remix-run/react';

import { Header } from '../header';

export const Layout = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation();

  return (
    <>
      {!pathname.includes('/track-attendance') &&
        !pathname.includes('/scoreboard') &&
        !pathname.includes('/scorebug') && <Header />}
      {children}
    </>
  );
};
