import * as React from 'react';

import { useNavigation } from '@remix-run/react';

import styles from './global-loading.module.css';

function GlobalLoading() {
  const transition = useNavigation();
  const active = transition.state !== 'idle';
  const [animationComplete, setAnimationComplete] = React.useState(true);

  React.useEffect(() => {
    if (transition.state === 'idle') {
      setTimeout(() => {
        setAnimationComplete(true);
      }, 300);
    } else {
      setAnimationComplete(false);
    }
  }, [transition.state]);

  const currentWidth =
    transition.state === 'idle' && !animationComplete
      ? '100%'
      : transition.state === 'loading'
      ? '83%'
      : transition.state === 'submitting'
      ? '33%'
      : 0;

  return (
    <div role="progressbar" aria-hidden={!active} aria-valuetext={active ? 'Loading' : undefined}>
      <div
        style={{
          width: currentWidth,
          opacity: transition.state === 'idle' && animationComplete ? 0 : 1,
        }}
        className={styles.bar}
      />
    </div>
  );
}

export { GlobalLoading };
