import styles from './styles.module.css';
import { withStyledComponents } from './with-styled-component';
import DodgeballLogo from '@images/logo.png';

const StyledSvg = withStyledComponents(styles.LogoSvg, 'h1');

export const Logo = ({ fill, size }: { fill?: string; size?: number }) => {
  return (
    <StyledSvg style={{ fontSize: `${size ?? 30}px`, color: `${fill ? fill : 'var(--main)'}` }}>
      <img src={DodgeballLogo} width={size ?? 150} alt="Dodgeball Hub" />
    </StyledSvg>
  );
};
