import React, { useContext, useEffect, useState } from 'react';
import dateRange from 'react-date-range/dist/styles.css?url';
import dateRangeThem from 'react-date-range/dist/theme/default.css?url';
import internationalPhone from 'react-international-phone/style.css?url';

import { cssBundleHref } from '@remix-run/css-bundle';
import { json, type LinksFunction, type LoaderFunctionArgs } from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useSearchParams,
} from '@remix-run/react';

import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Prompt from '@shared/prompt';

import styles from '~/index.css?url';
import axios from '~/utils/axios';
import * as gtag from '~/utils/gtags.client';

import { BackendEventToast } from './components/backend-event-toast';
import { GlobalLoading } from './components/global-loading';
import { InitialLoading } from './components/initial-loading';
import { Layout } from './components/Layout';
import { RenderEventForm } from './components/render-event-form';
import { AccessLevelProvider } from './providers/access-level-provider';
import { BallTypeListProvider } from './providers/ball-type-list-provider';
import ClientStyleContext from './providers/client-style-context';
import { DivisionListProvider } from './providers/division-list-provider';
import { EventFormProvider } from './providers/event-form-provider';
import { EventDashboardDataProvider } from './providers/events/event-dashboard-data-provider';
import { ListInteractionsProvider } from './providers/events/list-interactions-provider';
import { FeatureFlagsProvider } from './providers/feature-flags-provider';
import { OrganizationListProvider } from './providers/organization-list-provider';
import { PromptContextProvider } from './providers/prompt-provider';
import { ToastProvider } from './providers/toast-provider';
import { TokenProvider } from './providers/token-provider';
import { WindowContextProvider } from './providers/window-resize-provider';
import { getUserJWT } from './session.server';
import { withEmotionCache } from '@emotion/react';
import { sortBy } from 'lodash';
import { OverlayProvider } from './providers/overlay-provider';
import { WebSocketProvider } from './providers/websocket-provider';
import { PlayerInfoProvider } from './providers/player-info-provider';
import { useWebSocket } from './hooks/use-websocket';
import { getStreamingBannerEvents } from './utils/events/get-streaming-banner-events';
import { Event } from './types';
import { getRandomInt } from './utils/get-random-int';

const gaTrackingId = 'G-D0SG0J42F8';

export const links: LinksFunction = () => [
  ...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : []),
  {
    rel: 'stylesheet',
    href: styles,
  },
  {
    rel: 'stylesheet',
    href: dateRange,
  },
  {
    rel: 'stylesheet',
    href: dateRangeThem,
  },
  {
    rel: 'stylesheet',
    href: internationalPhone,
  },
];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const token = await getUserJWT(request);
  let organizations = [];
  let ballTypes = [];
  let divisions = [];
  let events = [];
  let streamingBannerEvent;

  axios.defaults.headers.common = { Authorization: `${token}` };

  try {
    const { data } = await axios.get(`/organizations`, { withCredentials: false });
    organizations = data.map(({ id, name, slug }: any) => ({ label: name, value: id, slug }));
  } catch (error) {
    console.error(error);
  }

  try {
    const { data } = await axios.get(`/divisions`, { withCredentials: false });
    divisions = sortBy(data, ['title']);
  } catch (error) {
    console.error(error);
  }

  try {
    const { data } = await axios.get(`/ball-types`, { withCredentials: false });
    ballTypes = sortBy(
      data.map(({ id, title }: any) => ({ label: title, value: id })),
      ['label'],
    );
  } catch (error) {
    console.error(error);
  }
  try {
    const { data } = await axios.get(`/events`, { withCredentials: false });
    events = data;
    const streamingEvents = getStreamingBannerEvents(events);
    const shownEvent = getRandomInt(0, streamingEvents.length - 1);
    streamingBannerEvent = streamingEvents[shownEvent];
  } catch (error) {
    console.error(error);
  }

  return json({
    organizations,
    ballTypes,
    divisions,
    token,
    events,
    streamingBannerEvent,
    ENV: {
      S3_ACCESS_KEY: process.env.S3_ACCESS_KEY,
      S3_SECRET_ACCESS_KEY: process.env.S3_SECRET_ACCESS_KEY,
      STRIPE_API_KEY: process.env.STRIPE_API_KEY,
    },
  });
};

interface DocumentProps {
  children: React.ReactNode;
  title?: string;
}

const Document = withEmotionCache(({ children, title }: DocumentProps, emotionCache) => {
  const clientStyleData = useContext(ClientStyleContext);
  const { pathname } = useLocation();
  const [queryParams] = useSearchParams();
  const data = useLoaderData<typeof loader>();

  // Only executed on client
  useEnhancedEffect(() => {
    // re-link sheet container
    emotionCache.sheet.container = document.head;
    // re-inject tags
    const tags = emotionCache.sheet.tags;
    emotionCache.sheet.flush();
    tags.forEach((tag) => {
      // eslint-disable-next-line no-underscore-dangle
      (emotionCache.sheet as any)._insertTag(tag);
    });
    // reset cache to reapply global styles
    clientStyleData.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {title ? <title>{title}</title> : null}
        <Meta />
        <Links />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <meta name="emotion-insertion-point" content="emotion-insertion-point" />
        {process.env.NODE_ENV === 'development' && (
          // eslint-disable-next-line @next/next/no-sync-scripts
          <script
            data-project-id="cGWoV5iibEFgIbHHxSGtBgWo43rAHOD8BpRu3s57"
            data-is-production-environment="false"
            src="https://snippet.meticulous.ai/v1/meticulous.js"
          />
        )}
      </head>
      <body
        suppressHydrationWarning
        className={`${queryParams.get('embedded') ? 'embedded' : ''} ${pathname.includes('/map') ? 'map' : ''} ${
          pathname.includes('/scoreboard') || pathname.includes('/scorebug') ? 'scoreboard' : ''
        }  ${pathname.includes('/track-attendance') ? 'track-attendance' : ''} ${
          pathname.includes('/scoreboard-control') || pathname.includes('/scoreboard/control')
            ? 'scoreboard-control'
            : ''
        }`}
      >
        {process.env.NODE_ENV === 'development' ? null : (
          <>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`} />
            <script
              async
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `,
              }}
            />
          </>
        )}
        {children}
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
          }}
        />
        <Scripts />
      </body>
    </html>
  );
});

export default function App() {
  const { pathname } = useLocation();
  const {
    organizations,
    ballTypes,
    divisions,
    token,
    events: initEvents,
    streamingBannerEvent,
  } = useLoaderData<typeof loader>();
  const [events, setEvents] = useState(initEvents);
  const { listenToMessage, stopListeningToMessage } = useWebSocket();

  useEffect(() => {
    async function onReloadEvent(value: any) {
      const { data } = await axios.get(`/events`, { withCredentials: false });
      setEvents(data);
    }

    listenToMessage('reload-events', onReloadEvent);

    return () => {
      stopListeningToMessage('reload-events');
    };
  }, []);

  useEffect(() => {
    gtag.pageview(pathname, gaTrackingId);
  }, [pathname]);
  const URL = process.env.NODE_ENV === 'production' ? 'https://api.dodgeballhub.com' : 'http://localhost:5001';

  return (
    <Document>
      <WebSocketProvider url={URL}>
        <TokenProvider token={token}>
          <PlayerInfoProvider>
            <FeatureFlagsProvider>
              <OverlayProvider>
                <PromptContextProvider>
                  <Prompt />
                  <WindowContextProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <EventFormProvider>
                        <OrganizationListProvider organizations={organizations}>
                          <BallTypeListProvider ballTypes={ballTypes}>
                            <DivisionListProvider divisions={divisions}>
                              <ToastProvider>
                                <RenderEventForm />
                                <AccessLevelProvider>
                                  <ListInteractionsProvider>
                                    <Layout>
                                      <EventDashboardDataProvider
                                        streamingBannerEvent={streamingBannerEvent as any}
                                        events={events}
                                      >
                                        <GlobalLoading />
                                        <BackendEventToast />
                                        <InitialLoading>
                                          <Outlet />
                                        </InitialLoading>
                                      </EventDashboardDataProvider>
                                    </Layout>
                                  </ListInteractionsProvider>
                                </AccessLevelProvider>

                                <div id="portal-root" />
                              </ToastProvider>
                            </DivisionListProvider>
                          </BallTypeListProvider>
                        </OrganizationListProvider>
                      </EventFormProvider>
                    </LocalizationProvider>
                  </WindowContextProvider>
                </PromptContextProvider>
              </OverlayProvider>
            </FeatureFlagsProvider>
          </PlayerInfoProvider>
        </TokenProvider>
      </WebSocketProvider>
    </Document>
  );
}
